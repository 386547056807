@import 'src/assets/variables.scss';

$primary: $ga-primary-red; // CORAL RED
$secondary: $ga-secondary-red;
$success: #00ecd1; // AQUA GREEN
$info: #fff; // HORIZON BLUE
$warning: #ffed57; // SOLAR YELLOW
//$danger: #dc3545;

// lower the min contrast ratio so that we get white text on the primary background
// see node_modules/bootstrap/scss/_variables.scss for the default value and details
$min-contrast-ratio: 2;

@import 'bootstrap/scss/functions.scss';
@import 'bootstrap/scss/variables.scss';

// merge with existing $theme-colors map
$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
  )
);

.dropdown-menu {
  overflow: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

:root {
  // generate the lightened background colors
  @each $state, $value in $theme-colors {
    --ga-bg-#{$state}-light: #{shift-color($value, -60%)};
  }
}

.ng-select-label {
  color: $ga-primary-red;
  font-style: normal;
  font-weight: 300;
  padding-top: 8px;
}

ng-select {
  padding-bottom: $space-between-inputs;
}

.alert {
  padding: 20px !important;

  fa-icon {
    color: $ga-primary-red;
  }

  .text {
    color: $ga-primary-red;
    font-size: 18px;
    font-weight: $default-text-weight;
  }

  &.alert-danger {
    border-radius: 10px;
    background: $ga-alert-danger;
  }
}

.ga-container {
  padding-left: 40px;
  padding-right: 40px;

  .card {
    border-radius: 50px;
    border-color: #fff;
    // border-top: 1.2px solid rgba(179, 75, 75, 0.54);
    background: rgba(216, 216, 216, 0.04);

    .card-header {
      border-radius: 47px 47px 0px 0px;
      background: rgba(196, 196, 196, 0.1);

      h3 {
        text-align: center;
        font-size: 36px;
        font-weight: 300;
      }
    }

    .card-body {
      padding: 20px 50px 50px 50px;
    }
  }

  .ga-overview {
    .ga-title {
      width: fit-content;
      padding: 0 25px 0 25px;
      color: $ga-text-primary;
      font-size: 42px;
      font-style: normal;
      font-weight: 300;
    }

    .ga-small-title {
      font-size: 32px;
    }

    .overview-divider {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 8px;
      width: 30%;
      background: $ga-light-red;
    }

    .ga-description {
      width: 60%;
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
    }
  }

  .ga-form {
  }

  button {
    border-radius: 18px;
  }

  .btn {
    &.btn-rounded {
      border-radius: 9999em;
      margin-left: -1em;
      padding-right: 1em;
    }

    &.btn-circle {
      border-radius: 50%;
    }
  }
}
