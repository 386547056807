// GRID APPS Colors
$ga-primary-red: #ff5d64;
$ga-secondary-red: #f89aa6;
$ga-alert-danger: #f9d1d4;
$ga-light-blue: #ebf5ff;
$ga-dark-red: #300911;

$ga-light-red: #ff5d6452;

$valid-input-color: #42a948;
$invalid-input-color: #a94442;

$ga-text-primary: #3d3d3d;

// Spacing
$space-between-inputs: 8px;

// Font and texts
$default-text-weight: 300;

// Nested tree
$nested-tree-base-width: 450px;
$nested-tree-active-icon-color: #fff;
$nested-tree-icon-color: #757575;
$nested-tree-item-height: 50px;
